import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

interface MetaContent {
    name: string;
    content: string;
}

type Props = {
    description?: string;
    lang?: string;
    meta?: Array<MetaContent>;
    keywords?: Array<string>;
    title: string;
};

function SEO({ description, lang = "en", meta = [], keywords = [], title }: Props) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = description || (data.site && data.site.description) || "";
                const siteTitle = (data.site && data.site.title) || "";
                const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";
                return (
                    <Helmet
                        htmlAttributes={{ lang }}
                        title={title}
                        titleTemplate={title === siteTitle ? "%s" : `%s | ${siteTitle}`}
                        meta={[
                            {
                                name: "description",
                                content: metaDescription
                            },
                            {
                                property: "og:title",
                                content: title
                            },
                            {
                                property: "og:description",
                                content: metaDescription
                            },
                            {
                                propery: "og:url",
                                content: "https://www.mattdunn.ca"
                            },
                            {
                                property: "og:type",
                                content: "website"
                            },
                            {
                                name: "twitter:card",
                                content: "summary"
                            },
                            {
                                name: "twitter:creator",
                                content: siteAuthor
                            },
                            {
                                name: "twitter:title",
                                content: title
                            },
                            {
                                name: "twitter:description",
                                content: metaDescription
                            },
                            {
                                name: "twitter:image:alt",
                                content: "Matt Dunn's Portfolio"
                            }
                        ]
                            .concat(
                                keywords && keywords.length > 0
                                    ? {
                                          name: "keywords",
                                          content: keywords.join(", ")
                                      }
                                    : []
                            )
                            .concat(meta)}
                    />
                );
            }}
        />
    );
}

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
            title
            description
            keywords
            author {
                name
            }
        }
    }
`;
